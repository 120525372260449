// React imports
import React from 'react';

// MSAL imports
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import Navbar from "react-bootstrap/Navbar";

// App imports
import AccumulatorList from "./components/AccumulatorList";
import SignInButton from "./components/SignInButton";
import SignOutButton from "./components/SignOutButton";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import ConfigData from "./config";

export const msalInstance = ConfigData.AUTHN_NULL ? null : new PublicClientApplication(msalConfig);

function App() {
    if (msalInstance != null) {
        return (
            <MsalProvider instance={msalInstance}>
                <Navbar>
                    <AuthenticatedTemplate><SignOutButton /></AuthenticatedTemplate>
                    <UnauthenticatedTemplate><SignInButton /></UnauthenticatedTemplate>
                </Navbar>
                <AuthenticatedTemplate>
                    <AccumulatorList />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <p data-cy="login">Please login.</p>
                </UnauthenticatedTemplate>
            </MsalProvider>
        );
    }

    return (
        <AccumulatorList />
    );
}

export default App;
