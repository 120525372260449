import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

export default function SignOutButton() {
    const { instance } = useMsal();

    function handleLogout() {
        instance.logoutRedirect({});
    }
    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogout()}>Sign out</Button>
    );
}
