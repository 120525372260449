import {Configuration, RedirectRequest} from "@azure/msal-browser";
import { ConfigData } from './config';

export const msalConfig: Configuration = {
    auth: {
        clientId:  ConfigData.OAUTH2_CLIENT_ID,
        authority: ConfigData.OAUTH2_AUTHORITY,
        redirectUri: ConfigData.OAUTH2_REDIRECT_URI,
        postLogoutRedirectUri: ConfigData.OAUTH2_POST_LOGOUT_REDIRECT_URI
    },
    cache: {
        cacheLocation: "sessionStorage",    // This configures where your cache will be stored
        storeAuthStateInCookie: false,      // Set this to "true" if you are having issues on IE11 or Edge
    },
    telemetry: {
        application: {
            appName: ConfigData.APP_NAME,
            appVersion: ConfigData.APP_VERSION,
        },
    }
}

export const loginRequest: RedirectRequest = {
    scopes: [
        "api://100a990c-f8c5-4a47-9e57-bc772ba1ab74/accumulator.admin",
        //"api://100a990c-f8c5-4a47-9e57-bc772ba1ab74/accumulator.reader",  // disabled until approved by an admin
        "api://100a990c-f8c5-4a47-9e57-bc772ba1ab74/accumulator.writer"
    ],
    domainHint: ConfigData.OAUTH2_DOMAIN_HINT
}
