import {stringToBool} from "./utils/types";

export const ConfigData = {
    // =========== METADATA Variables ==========
    APP_NAME: 'demo-accumulator-ui',
    APP_VERSION: '1.0.0',

    // =========== REQUIRED Variables ==========
    // @ts-ignore
    OAUTH2_AUTHORITY: window._env.OAUTH2_AUTHORITY || '',
    // @ts-ignore
    OAUTH2_CLIENT_ID: window._env.OAUTH2_CLIENT_ID || '',

    // =========== OPTIONAL Variables ==========
    // @ts-ignore
    API_ENDPOINT: window._env.API_ENDPOINT || 'http://localhost:8080/api/accumulator',
    // @ts-ignore
    PUBLIC_URL: window._env.PUBLIC_URL || '',
    // @ts-ignore
    AUTHN_NULL: stringToBool(window._env.AUTHN_NULL),
    // @ts-ignore
    OAUTH2_DOMAIN_HINT: window._env.OAUTH2_DOMAIN_HINT || 'mayo.edu',
    // @ts-ignore
    OAUTH2_REDIRECT_URI: window._env.OAUTH2_REDIRECT_URI || window._env.PUBLIC_URL || '',
    // @ts-ignore
    OAUTH2_POST_LOGOUT_REDIRECT_URI: window._env.OAUTH2_POST_LOGOUT_REDIRECT_URI || window._env.PUBLIC_URL || '',
}

export default ConfigData;
